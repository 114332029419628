jQuery(document).ready(function ($) {
    
    $('nav.navbar .collapse').on('show.bs.collapse hidden.bs.collapse', function (e) {
        $('.nav-overlay').toggleClass('show', e.type == "show");
        $('.menu-toggle').toggleClass('open', e.type == "show");
    });
    
    $(document).on('click', '.navbar-nav, .navbar-toggler', function (e) {
        e.stopImmediatePropagation();
    });
    
    $(document).on('click', function () {
        if ($('.nav-overlay').height() === 0) {
            return;
        }
        
        $('.navbar-toggler').click();
    });
    
    $(document).on('click', '.legal-link', function (e) {
        e.preventDefault();
        var legalLink = $(this).attr('href');
        PopupCenter(legalLink, 'popup', 650, 550);
    });
    
    /** change flickity svgs **/
    $('.flickity-prev-next-button.previous').html('<img src="/public/images/svg/arrow-right.svg" class="img-fluid">')
    $('.flickity-prev-next-button.next').html('<img src="/public/images/svg/arrow-right.svg" class="img-fluid">')
    
});//End doc.ready

function PopupCenter(url, title, w, h, options) {
    options = options || 'scrollbars=yes';
    // Fixes multi-monitor position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
    var width = $(window).width();
    var height = $(window).height();
    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, options + ', width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus();
    }
}